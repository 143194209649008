<template>
  <div class="aiz-user-panel">

    <div class="card">
      <div class="card-header row gutters-5">
        <div class="text-center text-md-left">
          <h5 class="mb-md-0 h5">{{detailData.Subject}}</h5>
          <div class="mt-2">
<!--            <span>{{userInfo.Email}}</span>-->
            <span class="ml-2">{{detailData.CreatedAt}}</span>
            <span class="badge badge-inline badge-secondary ml-2">{{detailData.Status}}</span>
          </div>
        </div>
      </div>
      <div class="pad-top">
        <ul class="list-group list-group-flush" style="padding: 1rem">
          <li class="list-group-item px-0" >
            <div class="media">
              <a class="media-left">
                  <span class="avatar avatar-sm mr-3">
                    <img :src="detailData.User.Photo || headerUrl">
                  </span>
              </a>
              <div class="media-body">
                <div class="comment-header">
                  <p class="text-muted text-sm fs-11">{{detailData.User.FullName}}</p>
<!--                  <p class="text-muted text-sm fs-11">{{detailData.CreatedAt}}</p>-->
                </div>
              </div>
            </div>
            <div>
              <div style="padding-left: 3.5rem">
                <a :href="`/#/product?id=${detailData.ProductID}`">{{detailData.Subject}}</a>
              </div>
              <div style="padding-left: 3.5rem" v-html="detailData.Content"></div>
            </div>
          </li>

        </ul>
      </div>
      <div class="card-body">

        <div class="pad-top">
          <ul class="list-group list-group-flush">
            <li class="list-group-item px-0" v-for="(item, i) in messageList" :key="i">
              <div class="media">
                <a class="media-left">
                  <span class="avatar avatar-sm mr-3">
                    <img :src="item.User.Photo || headerUrl">
                  </span>
                </a>
                <div class="media-body">
                  <div class="comment-header">
                    <p class="text-muted text-sm fs-11">{{item.User.FullName}}</p>
<!--                    <p class="text-muted text-sm fs-11">{{item.CreatedAt}}</p>-->
                  </div>
                </div>
              </div>
              <div>
                <div  style="padding-left: 3.5rem" v-html="item.Content"></div>
                <br>
              </div>
            </li>

          </ul>
        </div>
        <form >
          <div class="form-group">
            <textarea class="form-control mb-4" name="reply" rows="8" v-model="form.content"></textarea>
          </div>
          <div class="form-group mb-0 text-right">
            <button type="button" class="btn btn-sm btn-primary" @click="submit">{{ $t('fa-song-hui-fu') }}</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import previewBar from '@/components/preview'
import {Popup} from 'vant'
import { dialogueAdd, workOrderList, fileUpload  } from '@/api/user'
import {dialogueList, dialogueReply} from "@/api";
import { messageRead, unReadCount } from "@/api/seller";
export default {
  components: {
    pageBar,
    previewBar,
    Popup
  },
  data() {
    return {
      form: {
        content: ''
      },
      headerUrl: require('../../assets/imgs/avatar-place.png'),
      fileList: [],
      showPopup: false,
      userMoney: 10,
      messageList: [],
      detailData: {
        User: {}
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    let data = sessionStorage.getItem('dialogueList')
    if (data) {
      this.detailData = JSON.parse(data)
    }
    this.init()
    this.setRead()
  },
  methods: {
    setRead() {
      let form = new FormData()
      form.append('ID', this.dataId) 
      messageRead(form).then(res => {
        if (res.code == 0) {
          // 更新未读数
          unReadCount().then(res => {
            this.$store.state.messageCount = res.data
          })
        }
      })
    },
    init() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      form.append('ID', this.detailData.ID)
      dialogueList(form).then(res => {
        let list = res.data.Items[0]
        if (list.length > 0) {
          this.detailData = list[0]
        }
        console.log("this.detailData.DialogueReply")
        console.log(list)
        let messageList = list.DialogueReply
        let defaultMsg = {
          IsService: 2,
          CreatedAt: list.CreatedAt,
          Content: list.Content,
          User: list.User,
        }
        this.messageList = [].concat(messageList)
      })
    },
    addFile() {
      document.getElementById('fileInput').click()
    },
    uploadFile() {
      let file = document.getElementById('fileInput').files[0]
      let fileName = file.name
      let form = new FormData()
      form.append('file', file)
      fileUpload(form).then(res => {
        if (res.code == 0) {
          this.fileList.push({
            id: res.data,
            url: `/upload/${res.data}`,
            name: fileName
          })
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    removeFile(i) {
      this.fileList.splice(i, 1)
    },
    submit() {
      let form = new FormData()
      form.append('DialogueID', this.detailData.ID)
      form.append('Content', this.form.content)
      dialogueReply(form).then(res => {
        if (res.code == 0) {
          this.form.content = ''
          this.fileList = []
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>